<template>
  <div class="project_top">
    <van-nav-bar title="项目看板" fixed left-arrow @click-left="$router.back(-1)" />
    <van-cell-group inset class="first_project">
      <div class="title" style="text-align: center;">项目信息</div>
      <van-grid :border="false" :column-num="3">
        <van-grid-item class="bordered">
          <div>项目总数</div>
          <div class="info" @click="goToTarget('all')">{{ projectcount }}</div>
        </van-grid-item>
        <van-grid-item class="bordered">
          <div>安装数量</div>
          <div class="info" @click="goToTarget('doing_day')">{{ doingcount }}</div>
        </van-grid-item>
        <van-grid-item>
          <div>停工数量</div>
          <div class="info" @click="goToTarget('stop_day')">{{ stopcount }}</div>
        </van-grid-item>
      </van-grid>
    </van-cell-group>
    <van-cell-group inset>
      <div class="title" style="text-align: center;">任务信息</div>
      <van-grid :border="false" :column-num="3">
        <van-grid-item class="bordered">
          <div>昨日报工</div>
          <div class="info" @click="goToTarget('yesterday')">{{ yesterdaycount }}</div>
        </van-grid-item>
        <van-grid-item class="bordered">
          <div>7日报工</div>
          <div class="info" @click="goToTarget('aweek')">{{ aweekcount }}</div>
        </van-grid-item>
        <van-grid-item>
          <div>30日报工</div>
          <div class="info" @click="goToTarget('amonth')">{{ amonthcount }}</div>
        </van-grid-item>
      </van-grid>
    </van-cell-group>
    <van-cell-group inset>
      <div class="title" style="text-align: center;">安装情况</div>
      <ve-line :data="chartData" :settings="settings"></ve-line>
    </van-cell-group>
    <van-cell-group inset>
      <div class="title" style="text-align: center;">项目报工量</div>
      <div class="project_num" v-for="item in showProjectList" :key="item.id" @click="navToPage(item.no)">
        <van-row>
          <van-col span="12">项目号：{{ item.no }}</van-col>
          <van-col span="12">任务数：{{ item.totle_num }}</van-col>
        </van-row>
        <van-row>
          <van-col span="24">{{ item.name }}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">完成数：{{ item.finish_num }}</van-col>
          <van-col span="8" style="color: #f00;">昨日新增：{{ item.last_day_num }}</van-col>
          <van-col span="8" style="color: #00f;">上周新增：{{ item.last_week_num }}</van-col>
        </van-row>
      </div>
    </van-cell-group>
  </div>
</template>
<script>
import Projects from "@/api/project.js";
import Tasks from "@/api/task.js";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      projectcount: 0,
      doingcount: 0,
      stopcount: 0,
      yesterdaycount: 0,
      aweekcount: 0,
      amonthcount: 0,
      projectNumList: [],
      chartData: {
        columns: ["日期", "数量"],
        rows: []
      },
      settings: {
        label: {
          show: true,
          position: "top"
        }
      }
    };
  },
  computed: {
    ...mapState({
      dd: state => state.dd.dd
    }),
    showProjectList() {
      return this.projectNumList.filter(item => {
        return (
          item.totle_num > item.finish_num ||
          item.last_week_num > 0 ||
          item.last_day_num > 0
        );
      });
    }
  },
  methods: {
    getprojectcount() {
      Projects.projectcount().then(res => {
        this.projectcount = res.data.projectcount;
        this.doingcount = res.data.doingcount;
        this.stopcount = res.data.stopcount;
      });
    },
    gettaskcount() {
      Tasks.taskcount()
        .then(res => {
          this.yesterdaycount = res.data.yesterdaycount;
          this.aweekcount = res.data.aweekcount;
          this.amonthcount = res.data.amonthcount;
          this.chartData.rows = res.data.list;
          this.projectNumList = res.data.project_num_list;
        })
        .catch(e => {
          this.list = false;
        });
    },
    goToTarget(id) {
      this.$router.push({ name: "Tasklist", params: { id: id } });
    },
    navToPage(no) {
      this.$router.push({ name: "Task", params: { no: no } });
    }
  },
  created: function() {
    this.getprojectcount();
    this.gettaskcount();
  }
};
</script>

<style lang="scss">
div.project_top {
  padding-top: 30px;
  div.title {
    padding: 10px 10px;
    font-size: 17px;
    border-bottom: 1px solid #666;
  }
  .info {
    padding: 20px 20px;
    font-size: 20px;
  }
  .van-cell-group {
    border: 1px solid #666;
    margin: 20px 0;
  }
  .bordered {
    border-right: 1px solid #666;
  }
  div.project_num {
    padding: 10px;
    border-bottom: 1px solid #999;
    > div:not(:first-child) {
      padding-top: 10px;
    }
  }
  div.project_num:nth-child(odd) {
    background: #eee;
  }
}
</style>
